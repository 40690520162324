import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn' // 中文语言
import './assets/style/font.css';
import './assets/style/index.css';
import './assets/style/iconfont.css';
import Header from './components/Header'
import Footer from './components/Footer'
import Right from './components/Right'
import 'vant/lib/index.css';



const app = createApp(App);
app.component('Header', Header)
app.component('Footer', Footer)
app.component('Right', Right)
app.use(store)
app.use(router)
app.use(ElementPlus, {
    locale: locale,
    // 支持 large、default、small
    size: 'default'
})
app.mount('#app')
