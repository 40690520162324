<template>
  <router-view></router-view>
</template>

<style>
/* pc、平板（大于等于 768px） */
@media only screen and (min-width: 821px) {
  html {
    font-size: calc(100vw / 1920 * 100);
  }
}

/* 手机版（小于 767px 大于 320） */
@media only screen and (min-width: 320px) and (max-width: 820px) {
  html {
    font-size: calc(100vw / 750 * 100);
  }
}
</style>
