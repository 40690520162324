<template>
  <div class="row head fz18">
    <img
      src="../assets/image/logo-name.png"
      style="width:0.91rem;height:0.23rem;margin-left:2.77rem"
      @click="toPath('/home')"
    />
    <el-link class="fz18 ml40 link" :underline="false" @click="toPath('/home')">首页</el-link>
    <el-popover placement="bottom-start" :width="200">
      <template #reference>
        <el-link class="fz18 ml40 link" :underline="false">
          产品
          <el-icon class="el-icon--right" :size="14">
            <ArrowDownBold />
          </el-icon>
        </el-link>
      </template>
      <template #default>
        <div class="column-s">
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/moj')">MOJ云住中一体化服务系统</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/momo')">MOMO酒店AI语音服务系统</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/intercom')">MOMO智能AI对讲系统</el-link>
          <el-link class="fz14 link" :underline="false" @click="toPath('/meiyu')">美寓管家长租公寓管理系统</el-link>
        </div>
      </template>
    </el-popover>
    <el-popover placement="bottom-start" :width="200">
      <template #reference>
        <el-link class="fz18 ml40 link" :underline="false">
          方案
          <el-icon class="el-icon--right" :size="14">
            <ArrowDownBold />
          </el-icon>
        </el-link>
      </template>
      <template #default>
        <div class="column-s">
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/outBound')">酒店智能外呼方案</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/aiCloud')">酒店AI云务台解决方案</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/stranger')">酒店陌生人预警方案</el-link>
          <el-link
            class="fz14 mb14 link"
            :underline="false"
            @click="toPath('/selfService')"
          >无人自助酒店方案</el-link>
          <el-link class="fz14 link" :underline="false" @click="toPath('/aiot')">AloT数智客房解决方案</el-link>
        </div>
      </template>
    </el-popover>
    <el-popover placement="bottom-start" :width="200">
      <template #reference>
        <el-link class="fz18 ml40 link" :underline="false">
          能力
          <el-icon class="el-icon--right" :size="14">
            <ArrowDownBold />
          </el-icon>
        </el-link>
      </template>
      <template #default>
        <div class="column-s">
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/middleground')">酒店数字中台</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/order')">工单系统</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/mall')">商城系统</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/ai')">多轮次AI对话</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/face')">人脸识别API</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/mini')">对客小程序</el-link>
          <el-link class="fz14 link" :underline="false" @click="toPath('/notice')">多渠道通知及提醒</el-link>
        </div>
      </template>
    </el-popover>
    <el-link class="fz18 ml40 link" :underline="false" @click="toPath('/server')">服务</el-link>
    <el-link class="fz18 ml40 link" :underline="false" @click="toPath('/about')">关于我们</el-link>
    <div class="right" @click="toPath('/try')">申请体验</div>
  </div>
</template>

<script setup>
import { ArrowDownBold } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
const router = useRouter();

function toPath(params) {
  router.push(params);
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.head {
  position: fixed;
  top: 0;
  width: 19.2rem;
  height: 0.7rem;
  background: rgba(255, 255, 255, 1);
  z-index: 99;
  --el-link-hover-text-color: #304eeb;
}
.right {
  position: absolute;
  right: 2.62rem;
  border-radius: 0.15rem;
  border: 1px solid rgba(48, 78, 235, 1);
  color: rgba(48, 78, 235, 1);
  font-size: 0.16rem;
  padding: 0.04rem 0.2rem;
}
.link {
  --el-link-hover-text-color: #304eeb;
}
</style>
