<template>
  <div class="foot">
    <img
      @click="toPath('/try')"
      src="../assets/image/foot-img.png"
      style="width: 19.2rem;height:2.87rem"
    />
    <div class="content">
      <div class="row">
        <img
          src="../assets/image/logo-name.png"
          style="width: 1.14rem;height:0.29rem;margin-right:0.52rem"
        />
        <el-popover placement="top" :width="150" popper-style="border-radius: 0.2rem;">
          <template #reference>
            <span class="ml40 iconfont icon-meisuQW1 fz30 active"></span>
          </template>
          <template #default>
            <img src="../assets/image/qw.png" style="width:1.36rem;height:1.36rem" />
          </template>
        </el-popover>
        <el-popover placement="top" :width="150" popper-style="border-radius: 0.2rem;">
          <template #reference>
            <span class="ml40 iconfont icon-meisuGZH1 fz30 active"></span>
          </template>
          <template #default>
            <img src="../assets/image/gzh.png" style="width:1.36rem;height:1.36rem" />
          </template>
        </el-popover>
        <!-- <el-popover placement="top" :width="150" popper-style="border-radius: 0.2rem;">
          <template #reference>
            <span class="ml40 iconfont icon-meisuDY1 fz30 active"></span>
          </template>
          <template #default>
            <img src="../assets/image/wx.png" style="width:1.36rem;height:1.36rem" />
          </template>
        </el-popover>
        <el-popover placement="top" :width="150" popper-style="border-radius: 0.2rem;">
          <template #reference>
            <span class="ml40 iconfont icon-meisuWB1 fz30 active"></span>
          </template>
          <template #default>
            <img src="../assets/image/wx.png" style="width:1.36rem;height:1.36rem" />
          </template>
        </el-popover>
        <el-popover placement="top" :width="150" popper-style="border-radius: 0.2rem;">
          <template #reference>
            <span class="ml40 iconfont icon-meisuBLBL1 fz30 active"></span>
          </template>
          <template #default>
            <img src="../assets/image/wx.png" style="width:1.36rem;height:1.36rem" />
          </template>
        </el-popover>-->
      </div>
      <div class="row-s jc-between" style="margin-top: 0.65rem;">
        <div class="column-s">
          <div class="fz14 fw700" style="margin-bottom: 0.32rem;">产品服务</div>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/moj')">MOJ云住中一体化服务系统</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/momo')">MOMO酒店AI语音服务系统</el-link>
          <el-link
            class="fz14 mb14 link"
            :underline="false"
            @click="toPath('/intercom')"
          >MOMO智能AI对讲系统</el-link>
          <el-link class="fz14 link" :underline="false" @click="toPath('/meiyu')">美寓管家长租公寓管理系统</el-link>
        </div>
        <div class="column-s">
          <div class="fz14 fw700" style="margin-bottom: 0.32rem;">解决方案</div>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/outBound')">酒店智能外呼方案</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/aiCloud')">酒店AI云务台解决方案</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/stranger')">酒店陌生人预警方案</el-link>
          <el-link
            class="fz14 mb14 link"
            :underline="false"
            @click="toPath('/selfService')"
          >无人自助酒店方案</el-link>
          <el-link class="fz14 link" :underline="false" @click="toPath('/aiot')">AloT数智客房解决方案</el-link>
        </div>
        <div class="column-s">
          <div class="fz14 fw700" style="margin-bottom: 0.32rem;">能力输出</div>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/middleground')">酒店数字中台</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/order')">工单系统</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/mall')">商城系统</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/ai')">多轮次AI对话</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/face')">人脸识别API</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/mini')">对客小程序</el-link>
          <el-link class="fz14 link" :underline="false" @click="toPath('/notice')">多渠道通知及提醒</el-link>
        </div>
        <div class="column-s">
          <div class="fz14 fw700" style="margin-bottom: 0.32rem;">专业服务</div>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/server')">酒店数智化运营及建设规划</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/server')">无人自助酒店规划</el-link>
          <el-link class="fz14 mb14 link" :underline="false" @click="toPath('/server')">AI技术应用咨询及定制</el-link>
          <el-link class="fz14 link" :underline="false" @click="toPath('/server')">业务流程自动化咨询</el-link>
        </div>
        <div class="column-s">
          <div class="fz14 fw700" style="margin-bottom: 0.32rem;">联系我们</div>
          <div class="fz14 color1 mb14">商务邮箱：info@moj100.com</div>
          <div class="fz14 color1 mb14">联系电话：18605887428</div>
          <div class="fz14 color1">地址：杭州市余杭区赛银国际商务中心12幢4楼</div>
        </div>
      </div>
      <div class="column">
        <div class="row fz16" style="color: #888888;margin-top:0.84rem;padding-bottom:0.4rem">
          杭州美宿在途网络科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-link
            :underline="false"
            class="fz16"
            style="color: #888888;font-weight: 400;"
            @click="openToGJ"
          >浙ICP备2020034614号-4</el-link>&nbsp; 浙公安网备 16151682165
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

function toPath(params) {
  router.push(params);
}
function openToGJ() {
  window.open("https://beian.miit.gov.cn");
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.foot {
  position: relative;
  width: 19.2rem;
  background: #f3f5f8;
  z-index: 1;
  --el-link-hover-text-color: #304eeb;
}
.content {
  margin-top: 1.03rem;
  margin-left: 2.6rem;
  margin-right: 2.6rem;
  width: 14rem;
}
.color1 {
  color: #606266;
  font-weight: 500;
}
.link {
  --el-link-hover-text-color: #304eeb;
}
.active {
  color: #a0abbd;
}
.active:hover {
  color: #304eeb;
}
</style>
