<template>
  <div class="rigth">
    <div class="column">
      <el-popover placement="left" :width="150" popper-style="border-radius: 0.2rem;">
        <template #reference>
          <div class="icon mb14">
            <span class="iconfont icon-meisuDH" style="font-size: 0.4rem;"></span>
          </div>
        </template>
        <template #default>
          <div class="column-s">
            <div>联系电话</div>
            <div>18605887428</div>
          </div>
        </template>
      </el-popover>
      <el-popover placement="left" :width="240" popper-style="border-radius: 0.2rem;">
        <template #reference>
          <div class="icon mb14">
            <span class="iconfont icon-meisuWX" style="font-size: 0.4rem;"></span>
          </div>
        </template>
        <template #default>
          <div class="row">
            <img src="../assets/image/wx.png" style="width:1.36rem;height:1.36rem" />
            <div class="column-s fz18 ml10">
              <div>微信扫一扫</div>
              <div>商务咨询</div>
            </div>
          </div>
        </template>
      </el-popover>
      <el-popover placement="left" :width="150" popper-style="border-radius: 0.2rem;">
        <template #reference>
          <div class="icon mb14">
            <span class="iconfont icon-meisuLX" style="font-size: 0.4rem;"></span>
          </div>
        </template>
        <template #default>
          <div class="column-s">
            <div>商务合作及咨询</div>
            <div class="btn fz14" @click="toPath('/try')">联系我们 >></div>
          </div>
        </template>
      </el-popover>
      <el-backtop :right="100" :bottom="100" />
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

function toPath(params) {
  router.push(params);
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rigth {
  position: fixed;
  top: 4.61rem;
  right: 0.21rem;
  z-index: 88;
}
.icon {
  background: #ffffff;
  box-shadow: 0px 3px 12px rgb(25, 24, 36, 0.2);
  color: #000;
  width: 0.4rem;
  height: 0.4rem;
  font-size: 0.4rem;
  text-align: center;
  line-height: 0.4rem;
  border-radius: 0.2rem;
}
.icon:hover {
  background: #304eeb;
  color: #fff;
}
.btn {
  border-radius: 0.2rem;
  background: linear-gradient(270deg, #304eeb 0%, #1a88ff 100%);
  color: #fff;
  margin-top: 0.08rem;
  padding: 0.02rem 0.1rem;
}
</style>
