<template>
  <div class="home">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <el-carousel height="6.5rem">
      <el-carousel-item v-for="(item ,index) in bannerList" :key="index">
        <img
          v-if="index!=4"
          :src="item"
          style="width: 19.2rem;height:6.5rem"
          @click="toPath('/try')"
        />
        <img v-else :src="item" style="width: 19.2rem;height:6.5rem" @click="seeVideo()" />
      </el-carousel-item>
    </el-carousel>
    <div class="model0">
      <div class="row">
        <div class="column-s">
          <img src="../assets/image/home/MOJ.png" style="width: 3.7rem;height:1.05rem" class="mb25" />
          <img
            src="../assets/image/home/to.png"
            @click="toWeb('https://www.mojyun.com/#/login')"
            style="width:1.14rem;height:0.27rem"
          />
        </div>
        <div class="line" style="margin: 0 0.58rem 0 0.29rem;"></div>
        <div class="column-s">
          <img
            src="../assets/image/home/MOMO.png"
            style="width: 3.59rem;height:1.05rem"
            class="mb25"
          />
          <img
            src="../assets/image/home/to.png"
            @click="toWeb('https://momo.mojyun.com/admin/#/login')"
            style="width:1.14rem;height:0.27rem"
          />
        </div>
        <div class="line" style="margin: 0 0.69rem 0 0.51rem;"></div>
        <div class="column-s">
          <img src="../assets/image/home/MY.png" style="width: 3.62rem;height:1.01rem" class="mb30" />
          <img
            src="../assets/image/home/to.png"
            @click="toWeb('https://meiyu.mojyun.com/login?redirect=/index')"
            style="width:1.14rem;height:0.27rem"
          />
        </div>
      </div>
    </div>
    <div class="model1">
      <img src="../assets/image/home/model1.png" style="width: 8.37rem;height:3.42rem" />
    </div>
    <div class="model2 column">
      <img
        src="../assets/image/home/model2.png"
        @click="toPath('/moj')"
        style="width: 12.1rem;height:4.08rem;margin-top:1rem"
      />
    </div>
    <div class="model3 column">
      <img
        src="../assets/image/home/model3-title.png"
        @click="toPath('/momo')"
        style="width: 7.37rem;height:1.63rem;margin-top:1rem;margin-bottom:0.6rem"
      />
      <img src="../assets/image/home/model3-img.png" style="width: 14rem;height:4.82rem" />
    </div>
    <div class="model4 column">
      <img
        src="../assets/image/home/model4-title.png"
        @click="toPath('/meiyu')"
        style="width: 8.36rem;height:1.63rem;margin-top:1rem;margin-bottom:0.6rem"
      />
      <img src="../assets/image/home/model4-img.png" style="width: 14rem;height:4.77rem" />
    </div>
    <div class="model5 column">
      <img
        src="../assets/image/home/model5-title.png"
        style="width: 8.03rem;height:1.2rem;margin-top:1rem;margin-bottom:0.6rem"
      />
      <img src="../assets/image/home/model5-img.png" style="width: 14.76rem;height:4.38rem" />
    </div>
    <div class="model6 column">
      <img src="../assets/image/home/model6-bg.png" style="width: 19.2rem;height:4.54rem;" />
      <img
        src="../assets/image/home/model6-top.png"
        style="width: 14.78rem;height:6.55rem;position:relative;top:-2.7rem"
      />
      <img
        src="../assets/image/home/model6-title.png"
        style="width: 8.03rem;height:1.2rem;position:relative;top:-2rem"
      />
      <img
        src="../assets/image/home/model6-img.png"
        style="width: 14.76rem;height:4.38rem;position:relative;top:-1.6rem"
      />
    </div>
    <Right />
    <Footer />
    <el-dialog
      v-model="viewVideo"
      align-center
      :show-close="false"
      :before-close="handleCancel"
      :width="1000"
      class="dialog"
    >
      <video
        :src="videoUrl"
        :width="1000"
        autoplay="autoplay"
        controls="controls"
        :state="viewVideo"
        ref="vueRef"
      ></video>
    </el-dialog>
  </div>
</template>

<script setup>
import banner0 from "../assets/image/banner0.png";
import banner1 from "../assets/image/banner1.png";
import banner2 from "../assets/image/banner2.png";
import banner3 from "../assets/image/banner3.png";
import banner4 from "../assets/image/banner4.png";
const bannerList = [banner0, banner1, banner2, banner3, banner4];
import { useRouter } from "vue-router";
import { ref, getCurrentInstance } from "vue";
const router = useRouter();
const { proxy } = getCurrentInstance();
const videoUrl = ref(
  "https://mojphoto.oss-cn-hangzhou.aliyuncs.com/video/intercom.mp4"
);
const viewVideo = ref(false);

function seeVideo() {
  viewVideo.value = true;
  proxy.$refs.vueRef.play(); //播放
}

// 取消预览
function handleCancel(e) {
  viewVideo.value = false;
  proxy.$refs.vueRef.pause(); //暂停
}

function toPath(params) {
  router.push(params);
}
function toWeb(params) {
  window.open(params);
}
</script>
<style scoped>
.home {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  top: -0.2rem;
  width: 14rem;
  height: 2.5rem;
  border-radius: 0.18rem;
  background: linear-gradient(178.09deg, #f5f6f9 0%, #ffffff 100%);
  border: 0.01rem solid #ffffff;
  box-shadow: inset 0 0.14rem 0.2rem #f4f5f8,
    0 0.02rem 0.36rem rgba(3, 24, 133, 0.04);
  backdrop-filter: blur(0.04rem);
  margin-left: 2.6rem;
  padding: 0.43rem 0 0.5rem 0.36rem;
}
.line {
  height: 1.2rem;
  width: 0.01rem;
  background: #dae0f2;
}
.model1 {
  position: relative;
  margin-top: 0.65rem;
  margin-left: 5.24rem;
  margin-bottom: 1rem;
}
.model2 {
  position: relative;
  background: #fff;
  width: 19.2rem;
  height: 6.11rem;
}
.model3 {
  position: relative;
  width: 19.2rem;
  height: 9.07rem;
}
.model4 {
  position: relative;
  background: #fff;
  width: 19.2rem;
  height: 9.31rem;
}
.model5 {
  position: relative;
  width: 19.2rem;
  height: 7.68rem;
}
.model6 {
  position: relative;
  width: 19.2rem;
  height: 15.3rem;
}
>>> .el-dialog__header {
  padding: 0;
}
>>> .el-dialog__body {
  background: #000;
  /* opacity: 0.5; */
  padding: 0;
}
</style>
