import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { title: "美宿在途官网" }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { title: "关于我们" }
  },
  {
    path: '/server',
    name: 'Server',
    component: () => import(/* webpackChunkName: "about" */ '../views/Server.vue'),
    meta: { title: "服务" }
  },
  {
    path: '/middleground',
    name: 'Middleground',
    component: () => import(/* webpackChunkName: "about" */ '../views/power/Middleground.vue'),
    meta: { title: "酒店数字中台" }
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import(/* webpackChunkName: "about" */ '../views/power/Order.vue'),
    meta: { title: "工单系统" }
  },
  {
    path: '/mall',
    name: 'Mall',
    component: () => import(/* webpackChunkName: "about" */ '../views/power/Mall.vue'),
    meta: { title: "商城系统" }
  },
  {
    path: '/ai',
    name: 'AI',
    component: () => import(/* webpackChunkName: "about" */ '../views/power/AI.vue'),
    meta: { title: "多轮次AI对话" }
  },
  {
    path: '/face',
    name: 'Face',
    component: () => import(/* webpackChunkName: "about" */ '../views/power/Face.vue'),
    meta: { title: "人脸识别API" }
  },
  {
    path: '/mini',
    name: 'Mini',
    component: () => import(/* webpackChunkName: "about" */ '../views/power/Mini.vue'),
    meta: { title: "对客小程序" }
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import(/* webpackChunkName: "about" */ '../views/power/Notice.vue'),
    meta: { title: "多渠道通知及提醒" }
  },
  {
    path: '/outbound',
    name: 'Outbound',
    component: () => import(/* webpackChunkName: "about" */ '../views/plan/Outbound.vue'),
    meta: { title: "酒店智能外呼方案" }
  },
  {
    path: '/aiCloud',
    name: 'AICloud',
    component: () => import(/* webpackChunkName: "about" */ '../views/plan/AICloud.vue'),
    meta: { title: "酒店AI云务台解决方案" }
  },
  {
    path: '/stranger',
    name: 'Stranger',
    component: () => import(/* webpackChunkName: "about" */ '../views/plan/Stranger.vue'),
    meta: { title: "酒店陌生人预警方案" }
  },
  {
    path: '/selfService',
    name: 'SelfService',
    component: () => import(/* webpackChunkName: "about" */ '../views/plan/SelfService.vue'),
    meta: { title: "无人自助酒店方案" }
  },
  {
    path: '/aiot',
    name: 'AIOT',
    component: () => import(/* webpackChunkName: "about" */ '../views/plan/AIOT.vue'),
    meta: { title: "AloT数智客房解决方案" }
  },
  {
    path: '/momo',
    name: 'MOMO',
    component: () => import(/* webpackChunkName: "about" */ '../views/product/MOMO.vue'),
    meta: { title: "MOMO酒店AI语音服务系统" }
  },
  {
    path: '/moj',
    name: 'MOJ',
    component: () => import(/* webpackChunkName: "about" */ '../views/product/MOJ.vue'),
    meta: { title: "MOJ云住中一体化服务系统" }
  }
  ,
  {
    path: '/meiyu',
    name: 'MeiYu',
    component: () => import(/* webpackChunkName: "about" */ '../views/product/MeiYu.vue'),
    meta: { title: "美寓管家长租公寓管理系统" }
  }
  , {
    path: '/intercom',
    name: 'Intercom',
    component: () => import(/* webpackChunkName: "about" */ '../views/product/Intercom.vue'),
    meta: { title: "MOMO智能AI对讲系统" }
  }
  ,
  {
    path: '/try',
    name: 'Try',
    component: () => import(/* webpackChunkName: "about" */ '../views/Try.vue'),
    meta: { title: "申请使用" }
  },
  {
    path: '/phoneTry',
    name: 'PhoneTry',
    component: () => import(/* webpackChunkName: "about" */ '../views/PhoneTry.vue'),
    meta: { title: "申请使用" }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next()
});

export default router
